import React from 'react';
import IconTwo from '../../assets/images/icons/doctors/landing/10.png';
import IconThree from '../../assets/images/icons/doctors/landing/6.png';
import IconOne from '../../assets/images/icons/doctors/landing/9.png';


function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why use HomeXpert</h3>
                                {/* <p>
                                Don't let warranties be a source of stress. Elevate with Confidence
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={IconOne} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Digitalized Warranty</h4>
                                <p>
                                By replacing physical document and can retrieve from centralized warranty tracking system, so you can focus on enjoying your space to the fullest.                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconTwo} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Convenient Access</h4>
                                <p>
                                Access warranty details, expiration dates, and terms, so you're always in the know about your valuables and easily make claims directly from us.                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconThree} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Warranty Reminders</h4>
                                <p>
                                Stay ahead of the game with personalized warranty reminders. We ensure your home items receive the care they deserve, extending their lifespan.                                </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                <img src={IconFour} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Immediate shift coverage</h4>
                                <p>
                                Get paid what you deserve - even when facilities cancel at the last minute! Supermedic provides last minute cancellation compensation. 
                                </p>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconFive} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Your information </h4>
                                <p>
                                We respect your privacy. Facilities receive only essential info (name, contact) after a booking is confirmed. No personal documents are shared.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconSix} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">With you at every step</h4>
                                <p>
                                Stay updated throughout the process, from application to completion. Receive timely notifications.
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
