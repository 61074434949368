import React from 'react';
import BackToTop from './BackToTop';

function Privacy() {
    // const [drawer, drawerAction] = useToggle(false);

    return (
        <>
          <div 
        style={{
            width: '100%',
            height: '100vh',
            padding: '0px',
            margin: '0px',
            overflow: 'hidden'
        }}
          >
        <iframe title="privacy" src="https://docs.google.com/document/u/2/d/e/2PACX-1vRX4VjRenf8EuEKpud8BF4KBKqFkYdysc8SjpE4Y17-IG0sg-74hDFiHnQbYqM8OA/pub" 
            style={{
            width: '100%',
            height: '100%'

            }}
        ></iframe>
    </div>
            <BackToTop />
        </>
    );
}

export default Privacy;
