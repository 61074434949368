import React, { useEffect, useState } from "react";
import logo from "../assets/images/homexpert-logo.png";
import onlineguide1 from "../assets/images/onlineguide1.png";
import onlineguide2 from "../assets/images/onlineguide2.png";
import onlineguide3 from "../assets/images/onlineguide3.png";
import onlineguide4 from "../assets/images/onlineguide4.png";

import { androidURL, iosURL } from "../constants/urls";
function Redirect() {
  // const [drawer, drawerAction] = useToggle(false);
  const [code, setCode] = useState("");
  const [copyText, setCopyText] = useState("Copy to Clipboard");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("code");
    if (id) {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "text/plain");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: id,
        redirect: "follow",
      };

      fetch(
        "https://api.homexpert.com.my/warranty-user-app/decrypt",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setCode(result.data.invoice);
        })
        .catch(() =>  redirectToAppStore());
    } else {
      redirectToAppStore();
    }
  }, []);

  const redirectToAppStore = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log("redirecting", userAgent);
    let url = "https://homexpert.com.my/";
    let platform = "";
    if (/huawei/i.test(userAgent)) {
      url = "https://appgallery.huawei.com/app/C109020397";
      platform = "huawei";
    } else if (/android/i.test(userAgent)) {
      url =
        "https://play.google.com/store/apps/details?id=laoyi.HomExpert&hl=en";
      platform = "android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      url =
        "https://apps.apple.com/my/app/homexpert-warranty-service/id6472608588";
      platform = "ios";
    }
    if (platform === "") {
      window.location.href = url;
      console.log(url);
      return "unknown";
    }
    window.location.href = url;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt=""
        style={{
          height: 140,
          marginTop: 40,
        }}
      />

      <img src={onlineguide1} alt="HomExpert" />

      <div
        className="appie-hero-content appie-download-content"
        style={{ marginBottom: 48 }}
      >
        <ul>
          <li>
            <a href={iosURL}>
              <i className="fab fa-apple" />
              <span>
                Download for <span>iOS</span>
              </span>
            </a>
          </li>
          <li>
            <a className="item-2" href={androidURL}>
              <i className="fab fa-google-play" />
              <span>
                Download for <span>Android</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          border: 1,
          borderWidth: 1,
          borderColor: "#273590",
        }}
      >
        <h4>Supplier Code</h4>
        <h1 style={{ margin: 12, fontSize: 52, color: "#273590" }}>{code}</h1>
        <button
          style={{
            fontSize: 20,
            padding: 8,
            borderColor: "#273590",
            backgroundColor: "white",
            borderRadius: 12,
            borderWidth: 1,
            marginTop: 4,
            width: 300,
            height: 50,
          }}
          onClick={() => {
            navigator.clipboard.writeText(code);
            setCopyText("Copied!");
          }}
        >
          {copyText}
        </button>
      </div>
      <img src={onlineguide2} alt="HomExpert" />

      <img src={onlineguide3} alt="HomExpert" />

      <img src={onlineguide4} alt="HomExpert" />
    </div>
  );
}

export default Redirect;
