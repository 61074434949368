import React from 'react';
import heroThumbOne from '../../assets/images/home-main.png';
import logo from '../../assets/images/homexpert-logo.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

import {
    androidURL,
    iosURL
} from '../../constants/urls';
function HeroHomeOne({ className }) {
  
    return (
        <>
            <section style={{marginTop: -100}} className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content appie-download-content">
                                <img src={logo} alt="" style={{height: 100, marginBottom: 32}} />
                                
                                {/* <span>Welcome To HomeXpert</span> */}
                                <h1 className="appie-title">Warranty Tracking Reimagined</h1>
                                <p>
                                Unlock peace of mind with us, the cutting-edge mobile app designed to revolutionize the way you manage and safeguard your valuables.                                </p>
                                <ul>
                                    <li>
                                        <a href={iosURL}>
                                            <i className="fab fa-apple" />
                                            <span>
                                                Download for <span>iOS</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href={androidURL}>
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Download for <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" height={650} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
