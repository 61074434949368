import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoFacilities from '../../assets/images/supermedic-facilities-logo.png';
import logo from '../../assets/images/supermedic-logo.png';

function Drawer({ drawer, action, lang, isFacilities = false  }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            {lang ? (
                <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close">
                                            <a href="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </a>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <img src={isFacilities ? logoFacilities : logo } alt="" />
                                        </div>
                                        <div id="menu" className="text-left ">
                                            <ul className="offcanvas_main_menu">
                                              
                                                <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/service">خدمة</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'pages')}
                                                    id="pages"
                                                    className="menu-item-has-children active"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a href="#">الصفحات</a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'pages' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <ul className="sub-menu">
                                                            <li>
                                                                <Link to="/about-us">عن</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/error">خطأ</Link>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'news')}
                                                    id="news"
                                                    className="menu-item-has-children active"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a href="#">أخبار</a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'news' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <ul className="sub-menu">
                                                            <li>
                                                                <Link to="/news">
                                                                    صفحة الأخبار{' '}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/news/single-news">
                                                                    أخبار واحدة
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/contact">اتصل</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="offcanvas-social">
                                            <ul className="text-center">
                                                <li>
                                                    <a href="$">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="$">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="$">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="$">
                                                        <i className="fab fa-dribbble"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="footer-widget-info">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-envelope"></i>{' '}
                                                        support@appie.com
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-phone"></i> +(642) 342
                                                        762 44
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-map-marker-alt"></i>{' '}
                                                        442 Belle Terre St Floor 7, San Francisco,
                                                        AV 4206
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close">
                                            <a href="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </a>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div id="menu" className="text-left ">
                                            <ul className="offcanvas_main_menu">
                                            
                                                <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                        <a href="/">Home</a>
                                                        </li>
                                                    <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                    <a href="/about-us">About us</a>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/contact">Contact</Link>
                                                    </li>
                                                    <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                  
                                                </li>
                                            </ul>
                                        </div>
                            
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Drawer;
