import React from 'react';
import IconOne from '../../assets/images/icons/doctors/landing/1.png';
import IconTwo from '../../assets/images/icons/doctors/landing/2.png';
import IconThree from '../../assets/images/icons/doctors/landing/3.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title" style={{color: '#283891', lineHeight: 1}}>
                            Experience a stress-free & seamless approach
                            </h3>
                        {/* <p>with 3 simple steps</p> */}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">Scan & Register with QR Code</h4>
                            <p>Save time with our generated warranty QR code to register easily</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Keep Track of Warranty Status</h4>
                            <p>Fully-featured mobile application track status of your products</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">Raise & Submit Claims Directly</h4>
                            <p>Whenever there’s an issue upload & submit claims in the mobile app</p>
                        </div>
                    </div>
                 
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
