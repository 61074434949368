import React from 'react';
import BackToTop from '../BackToTop';
import DownloadHomeTwo from './DownloadHomeTwo';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import ServicesHomeTwo from './ServicesHomeTwo';

function HomeOne() {
    // const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
            {/* <HomeOneHeader action={drawerAction.toggle} /> */}
            <HeroHomeOne />
            <ServicesHomeOne />
            {/* <FeaturesHomeOne /> */}
            <ServicesHomeTwo className="pt-90" />
            {/* <VideoPlayerHomeTwo /> */}
            {/* <WorkPartHomeEight /> */}
            {/* <TestimonialHomeOne /> */}
            <DownloadHomeTwo className="mb-0" />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
